<template>
  <div>
    <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入名称" />
        </a-form-model-item>
        <a-form-model-item label="英文名称" prop="englishName">
          <a-input v-model="form.englishName" placeholder="请输入英文名称" />
        </a-form-model-item>
        <!--    <a-form-model-item label="剧本时长" prop="estimatedTime">-->
        <!--      <a-input v-model="form.estimatedTime" placeholder="请输入内容" type="textarea" allow-clear />-->
        <!--    </a-form-model-item>-->
        <a-form-model-item label="剧本时长" prop="estimatedTimeId">
          <a-select
            v-model="form.estimatedTimeId"
            style="width: 100%"
            placeholder="请选择剧本时长"
            :options="scriptEstimatedTimeIdOptions"
            @change="selectEstimatedTimeIdHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="剧本时长配置（查询使用）（例如3.5）" prop="estimatedTime">
          <a-input-number v-model="form.estimatedTime" :max="10" :precision="1" placeholder="请输入剧本时长配置（查询使用）" allow-clear/>
        </a-form-model-item>
        <!--    <a-form-model-item label="启用状态" prop="isEnable">
              <a-select
                v-model="form.isEnable"
                style="width: 100%"
                placeholder="请选择启用状态"
                :options="this.customDictOptions"
                @change="selectHandleChange"
              ></a-select>
            </a-form-model-item>-->
        <a-form-model-item label="手动设置评分文字" prop="manualScoreType">
          <a-select style="width: 250px" v-model="form.manualScoreType" placeholder="请设置评分文字">
            <a-select-option :value="0">默认</a-select-option>
            <a-select-option :value="1">年度黑马</a-select-option>
            <a-select-option :value="2">老少皆宜</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="启用状态" prop="isEnable">
          <a-switch v-model="form.isEnable" />{{ form.isEnable ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="是否上新" prop="isNew">
          <a-switch v-model="form.isNew" />{{ form.isNew ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="发行时间" prop="releaseTime">
          <a-date-picker v-model="form.releaseTime" style="width: 100%" @change="onDateChange" format="YYYY-MM-DD" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="是否封分" prop="isCloseScore">
          <a-switch v-model="form.isCloseScore" />{{ form.isCloseScore ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="人气榜置顶" prop="isPopularTop">
          <a-switch v-model="form.isPopularTop" />{{ form.isPopularTop ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="口碑榜置顶" prop="isPopinionsTop">
          <a-switch v-model="form.isPopinionsTop" />{{ form.isPopinionsTop ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="是否每日热搜置顶" prop="isHotTop">
          <a-switch v-model="form.isHotTop" />{{ form.isHotTop ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="是否想玩榜置顶" prop="isLikeRankTop">
          <a-switch v-model="form.isLikeRankTop" />{{ form.isLikeRankTop ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="是否高分榜置顶" prop="isScoreRankTop">
          <a-switch v-model="form.isScoreRankTop" />{{ form.isScoreRankTop ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="列表封面" prop="cover">
          <oss-multi-upload
            v-model="form.cover"
            type="img"
            :maxCount="1"
            :maxSize="10"
            :isMultiple="false"
          ></oss-multi-upload>
        </a-form-model-item>
        <a-form-model-item label="是否自定义剧本详情主色调" prop="isDiyAverageHue">
          <a-switch v-model="form.isDiyAverageHue" />{{ form.isDiyAverageHue ? '是' : '否' }}
        </a-form-model-item>
        <a-form-model-item label="封面图主色调" v-if="form.isDiyAverageHue" prop="averageHue">
<!--          <div :style="{ background: form.averageHue, width: '20px', height: '20px' }"></div>-->
          <chrome-picker v-model="form.averageHue" @input="updateColor" />
        </a-form-model-item>
        <a-form-model-item label="详情(多图)" prop="info">
          <a-upload
            name="info"
            v-model="fileList"
            listType="picture-card"
            :fileList="fileList"
            :remove="removeFile"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="true"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="coverAvatarUploadInfo">
            <div>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="剧本标签" prop="label">
          <a-select
            v-model="labels"
            mode="multiple"
            style="width: 100%"
            placeholder="请选择剧本标签(可选择多个)"
            :options="scriptLabelOptions"
            @change="selectLabelHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="背景" prop="filterBackground">
          <a-select
            v-model="form.filterBackground"
            style="width: 100%"
            placeholder="请选择背景"
            :options="scriptFilterBackgroundOptions"
            @change="selectFilterBackgroundHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="题材" prop="filterTheme">
          <a-select
            v-model="filterThemes"
            style="width: 100%"
            placeholder="请选择题材"
            mode="multiple"
            :options="scriptFilterThemeOptions"
            @change="selectFilterThemeHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="难度" prop="filterDifficulty">
          <a-select
            v-model="form.filterDifficulty"
            style="width: 100%"
            placeholder="请选择难度"
            :options="scriptFilterDifficultyOptions"
            @change="selectFilterDifficultyHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="发售形式" prop="filterSellForm">
          <a-select
            v-model="form.filterSellForm"
            style="width: 100%"
            placeholder="请选择发售形式"
            :options="scriptFilterSellFormOptions"
            @change="selectFilterSellFormHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="filterType">
          <a-select
            v-model="form.filterType"
            style="width: 100%"
            placeholder="请选择难度"
            :options="scriptFilterTypeOptions"
            @change="selectFilterTypeHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="人数" prop="personNum">
          <a-select
            v-model="form.personNum"
            style="width: 100%"
            placeholder="请选择人数"
            :options="scriptPersonNumOptions"
            @change="selectPersonNumHandleChange"></a-select>
        </a-form-model-item>
        <a-form-model-item label="男生人数" prop="humanNum">
          <a-input v-model="form.humanNum" placeholder="请输入男生人数" />
        </a-form-model-item>
        <a-form-model-item label="女生人数" prop="womanNum">
          <a-input v-model="form.womanNum" placeholder="请输入女生人数" />
        </a-form-model-item>
        <a-form-model-item label="故事背景" prop="storyBackground">
          <a-textarea v-model="form.storyBackground" placeholder="请输入内容" :rows="4" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="适龄岁数阶级 具体参考AppropriateAgeGroupEnum" prop="appropriateAgeGroup">
          <a-select v-model="form.appropriateAgeGroup" placeholder="请选择适龄岁数阶级" style="width: 100%" allowClear>
            <a-select-option v-for="(value, key) in appropriateAgeGroupMap" :key="key" :value="parseInt(key)">
              {{ value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发行" prop="seller">
          <a-select
            v-model="sellers"
            mode="multiple"
            label-in-value
            placeholder="请选择发行"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            :options="sellerData"
            @search="fetchSellerData"
            @change="fetchSellerChange">
            <template v-if="fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发行展示头像" prop="sellerLogo">
          <div>
            <img v-if="form.sellerLogo" :src="form.sellerLogo" style="width:100px;height:100px;" @preview="handlePreview" alt=""/>
            <br v-if="form.sellerLogo">
            <a-button type="dashed" @click="$refs.scriptConfigComponents.open(form.seller)" danger>
              <span style="color: dodgerblue"> 绑定头像</span>
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="作者" prop="author">
          <a-input v-model="form.author" placeholder="请输入内容" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="监制" prop="producer">
          <a-input v-model="form.producer" placeholder="请输入内容" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="真实热搜值" prop="hotValue" v-if="formType === 2">
          <a-input-number v-model="form.hotValue" style="width: 100%" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="虚拟热搜值" prop="hotValueVirtual">
          <a-input-number v-model="form.hotValueVirtual" style="width: 100%" placeholder="请输入虚拟热搜值" />
        </a-form-model-item>
        <a-form-model-item label="真实点赞值" prop="likeValue" v-if="formType === 2">
          <a-input-number v-model="form.likeValue" :precision="0" style="width: 100%" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="虚拟点赞值" prop="likeValueVirtual">
          <a-input-number v-model="form.likeValueVirtual" :precision="0" style="width: 100%" placeholder="请输入虚拟点赞值" />
        </a-form-model-item>
        <a-form-model-item label="真实已玩过值" prop="playedValue" v-if="formType === 2">
          <a-input-number v-model="form.playedValue" :precision="0" style="width: 100%" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="虚拟已玩过值" prop="playedValueVirtual">
          <a-input-number v-model="form.playedValueVirtual" :precision="0" style="width: 100%" placeholder="请输入虚拟已玩过值"/>
        </a-form-model-item>
<!--        <a-form-model-item label="真实票房值" prop="salesValue">-->
<!--          <a-input-number v-model="form.salesValue" style="width: 100%" :disabled="true" />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="虚拟票房值" prop="salesValueVirtual">-->
<!--          <a-input-number v-model="form.salesValueVirtual" style="width: 100%" placeholder="请输入虚拟票房值" />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="真实人气值 " prop="popularValue" v-if="formType === 2">
          <a-input-number v-model="form.popularValue" style="width: 100%" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="虚拟人气值 " prop="popularValueVirtual">
          <a-input-number v-model="form.popularValueVirtual" style="width: 100%" placeholder="请输入虚拟人气值 " />
        </a-form-model-item>
        <a-form-model-item label="真实题材值" prop="themeValue" v-if="formType === 2">
          <a-input-number v-model="form.themeValue" style="width: 100%" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="虚拟题材值" prop="themeValueVirtual">
          <a-input-number v-model="form.themeValueVirtual" style="width: 100%" placeholder="请输入虚拟题材值" />
        </a-form-model-item>
        <a-form-model-item label="真实想玩期待值" prop="themeValueVirtual" v-if="formType === 2">
          <a-input-number v-model="form.likeRankValue" style="width: 100%" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="虚拟想玩期待值" prop="themeValueVirtual">
          <a-input-number v-model="form.likeRankValueVirtual" style="width: 100%" placeholder="请输入虚拟想玩期待值" />
        </a-form-model-item>
        <a-form-model-item label="真实评分值" prop="scoreValue" v-if="formType === 2">
          <a-input-number v-model="form.scoreValue" :precision="1" style="width: 100px" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="虚拟评分值" prop="scoreValueVirtual">
          <a-input-number v-model="form.scoreValueVirtual" :precision="1" :min="-5" :max="5" style="width: 100px"/>
        </a-form-model-item>
        <a-form-model-item label="角色" prop="role">
          <!-- 角色修改弹窗 -->
          <multi-role-form ref="multiRoleForm" v-model="form.role"></multi-role-form>
          <div class="roleLabelClass" v-for="(item, index) in form.role" :key="index" style="float: left">
            <a-button type="dashed" @click="roleClick(index)" danger>{{ item.roleName }}</a-button>
          </div>
          <!-- 角色添加按钮 -->
          <div v-show="!form.role || !form.role.length">
            <a-button type="dashed" @click="roleClick(undefined)" danger><span style="color: dodgerblue">新增角色</span>
            </a-button>
          </div>
        </a-form-model-item>
        <!--      <a-form-model-item label="发行有话说类型" prop="talkType">
          <a-select
            v-model="form.talkType"
            style="width: 100%"
            placeholder="请选择发行有话说类型"
            :options="talkTypeOptions"
            @change="selectTalkTypeHandleChange"
          ></a-select>
        </a-form-model-item>-->
        <a-form-model-item label="发行有话说类型" prop="talkType">
          <a-select style="width: 100%" v-model="form.talkType" placeholder="请输入发行有话说类型">
            <a-select-option v-for="(item, index) in this.customDict.TalkTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作者监制发行说显示名称" prop="remark">
          <a-input v-model="form.remark" placeholder="请输入内容" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="发行有话说" prop="talkInfo">
          <template>
            <a-card>
              <a-form-model-item label="配图(可上传多个)" prop="talkInfo.image">
                <oss-multi-upload v-model="form.talkInfo.image" type="img" :maxSize="10"></oss-multi-upload>
              </a-form-model-item>
              <a-form-model-item label="视频(可上传多个)" prop="talkInfo.video">
                <oss-multi-upload v-model="form.talkInfo.video" type="video" :maxSize="200"></oss-multi-upload>
              </a-form-model-item>
              <a-form-model-item label="标签(多个逗号分隔,请使用英文逗号！！！)" prop="talkInfo.labels">
                <a-input
                  v-model="form.talkInfo.labels"
                  placeholder="例：标签一,标签二,标签三"
                  type="textarea"
                  allow-clear />
              </a-form-model-item>
              <a-form-model-item label="有话说" prop="talkInfo.text">
                <a-input v-model="form.talkInfo.text" placeholder="请输入内容" type="textarea" allow-clear />
              </a-form-model-item>
            </a-card>
          </template>
        </a-form-model-item>
        <a-form-model-item label="官方评测" prop="officialEvaluation">
          <template>
            <a-card>
              <a-form-model-item label="推荐玩家" prop="officialEvaluation.recommendPlayer">
                <a-input
                  v-model="form.officialEvaluation.recommendPlayer"
                  placeholder="请输入内容"
                  type="textarea"
                  allow-clear />
              </a-form-model-item>
              <a-form-model-item label="剧本亮点" prop="officialEvaluation.highlight">
                <a-input
                  v-model="form.officialEvaluation.highlight"
                  placeholder="请输入内容"
                  type="textarea"
                  allow-clear />
              </a-form-model-item>
              <a-form-model-item label="故事性" prop="officialEvaluation.story">
                <a-input
                  v-model="form.officialEvaluation.story"
                  placeholder="例：标签一,标签二,标签三"
                  type="textarea"
                  allow-clear />
              </a-form-model-item>
              <a-form-model-item label="体验感" prop="officialEvaluation.experience">
                <a-input
                  v-model="form.officialEvaluation.experience"
                  placeholder="请输入内容"
                  type="textarea"
                  allow-clear />
              </a-form-model-item>
              <a-form-model-item label="可玩性" prop="officialEvaluation.playability">
                <a-input
                  v-model="form.officialEvaluation.playability"
                  placeholder="请输入内容"
                  type="textarea"
                  allow-clear />
              </a-form-model-item>
            </a-card>
          </template>
        </a-form-model-item>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <ScriptConfigComponents
      ref="scriptConfigComponents"
      @bindLogo="bindLogo"
    />
  </div>
</template>

<script>
import { getScript, addScript, updateScript, appropriateAgeGroupMap } from '@/api/script/script'
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
import { mapGetters } from 'vuex'
import { totalListLabel } from '@/api/config/label'
import Template from '@/views/sms/template/index.vue'
import { loadFilterOptions } from '@/api/script/scriptFilter'
import { loadOptionsByName } from '@/api/script/scriptConfig'
import MultiRoleForm from './MultiRoleForm.vue'
import OssMultiUpload from './OssMultiUpload.vue'
import BatchBindScriptModal from '@/views/expo/expoScript/modules/BatchBindScriptModal.vue'
import ScriptConfigComponents from '../modules/ScriptConfigComponents.vue'
import { Chrome } from 'vue-color'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    BatchBindScriptModal,
    Template,
    MultiRoleForm,
    OssMultiUpload,
    ScriptConfigComponents,
    'chrome-picker': Chrome
  },
  data() {
    return {
      submitLoading: false,
      loading: '',
      talkLoading: '',
      talkInfoLabelArr: [],
      talkVideoLoading: '',
      formTitle: '',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fetching: '',
      pictureType: 'picture-card',
      scriptLabelOptions: [],
      scriptFilterBackgroundOptions: [],
      scriptFilterTypeOptions: [],
      scriptFilterThemeOptions: [],
      scriptFilterSellFormOptions: [],
      scriptFilterDifficultyOptions: [],
      scriptEstimatedTimeIdOptions: [],
      scriptPersonNumOptions: [],
      talkTypeOptions: [],
      customDictOptions: [],
      authorData: [],
      sellerData: [],
      producerData: [],
      labels: [],
      authors: [],
      sellers: [],
      producers: [],
      filterThemes: [],
      // 表单参数
      form: {
        id: null,
        shopId: null,
        cover: null,
        name: null,
        englishName: null,
        info: null,
        label: null,
        isDiyAverageHue: false,
        averageHue: '#000000',
        filterBackground: null,
        filterTheme: null,
        filterDifficulty: null,
        estimatedTimeId: null,
        filterSellForm: null,
        filterType: null,
        personNum: null,
        humanNum: null,
        womanNum: null,
        talkType: null,
        talkInfo: {
          image: null,
          text: null,
          labels: null,
          video: null
        },
        author: null,
        seller: null,
        sellerLogo: null,
        producer: null,
        storyBackground: null,
        officialEvaluation: {
          recommendPlayer: null,
          highlight: null,
          story: null,
          experience: null,
          playability: null
        },
        hotValue: null,
        hotValueVirtual: null,
        likeValue: null,
        likeValueVirtual: null,
        playedValue: null,
        playedValueVirtual: null,
        salesValue: null,
        salesValueVirtual: null,
        popularValue: null,
        popularValueVirtual: null,
        themeValue: null,
        themeValueVirtual: null,
        likeRankValueVirtual: 0,
        scoreValueVirtual: null,
        role: [],
        manualScoreType: null,
        isEnable: null,
        isNew: null,
        isCloseScore: null,
        isPopularTop: null,
        releaseTime: null,
        isPopinionsTop: null,
        isHotTop: null,
        isLikeRankTop: null,
        isScoreRankTop: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        estimatedTime: null,
        version: null,
        appropriateAgeGroup: null,
      },
      // 是否之前无主题色
      isNonAverageHue: true,
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        // label: [
        //    {required: true, message: '剧本标签不能为空', trigger: 'blur'}
        //  ],
        cover: [
          { required: true, message: '列表封面不能为空', trigger: 'blur' }
        ],
        info: [
          { required: true, message: '详情（多图）不能为空', trigger: 'blur' }
        ],
        filterBackground: [
          { required: true, message: '背景不能为空', trigger: 'blur' }
        ],
        filterTheme: [
          { required: true, message: '题材不能为空', trigger: 'blur' }
        ],
        filterDifficulty: [
          { required: true, message: '难度不能为空', trigger: 'blur' }
        ],
        filterSellForm: [
          { required: true, message: '发售形式不能为空', trigger: 'blur' }
        ],
        filterType: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
        personNum: [
          { required: true, message: '人数不能为空', trigger: 'blur' }
        ],
        humanNum: [
          { required: true, message: '男生人数不能为空', trigger: 'blur' }
        ],
        womanNum: [
          { required: true, message: '女生人数不能为空', trigger: 'blur' }
        ],
        seller: [
          { required: true, message: '发行不能为空', trigger: 'blur' }
        ],
        // sellerLogo: [
        //   { required: true, message: '发行头像不能为空', trigger: 'blur' }
        // ],
        estimatedTimeId: [
          { required: true, message: '剧本时长不能为空', trigger: 'blur' }
        ],
        estimatedTime: [
          { required: true, message: '剧本时长配置不能为空', trigger: 'blur' }
        ],
        releaseTime: [
          { required: true, message: '发行时间不能为空', trigger: 'change' }
        ],
        // talkType: [
        //     {required: true, message: '发行有话说类型不能为空', trigger: 'change'}
        //   ],
        //
        // author: [
        //    {required: true, message: '作者不能为空', trigger: 'blur'}
        //  ],
        //
        //  producer: [
        //    {required: true, message: '监制不能为空', trigger: 'blur'}
        //  ],
        //
        // hotValue: [
        //   {required: true, message: '热搜值不能为空', trigger: 'blur'}
        // ],
        //
        // hotValueVirtual: [
        //   {required: true, message: '虚拟热搜值不能为空', trigger: 'blur'}
        // ],
        //
        // likeValue: [
        //   {required: true, message: '点赞值不能为空', trigger: 'blur'}
        // ],
        //
        // likeValueVirtual: [
        //   {required: true, message: '虚拟点赞值不能为空', trigger: 'blur'}
        // ],
        // popularValueVirtual: [
        //   {required: true, message: '虚拟人气值不能为空', trigger: 'blur'}
        // ],
        // salesValue: [
        //   {required: true, message: '票房值不能为空', trigger: 'blur'}
        // ],
        //
        // salesValueVirtual: [
        //   {required: true, message: '虚拟票房值不能为空', trigger: 'blur'}
        // ],
        //
        // themeValue: [
        //   {required: true, message: '题材值不能为空', trigger: 'blur'}
        // ],
        //
        // themeValueVirtual: [
        //   {required: true, message: '虚拟题材值不能为空', trigger: 'blur'}
        // ],
        isEnable: [
          { required: true, message: '启用状态不能为空', trigger: 'change' }
        ]
        // isDeleted: [
        //    {required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur'}
        //  ],
        //  version: [
        //    {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        //  ]
      },
      appropriateAgeGroupMap: []
    }
  },
  filters: {},
  created() {
    appropriateAgeGroupMap().then(res => {
      this.appropriateAgeGroupMap = res.data
    })
    this.reset()
    this.initScriptLabelOptions()
    this.initScriptFilterBackgroundOptions()
    this.initScriptFilterTypeOptions()
    this.initScriptFilterThemeHandleOptions()
    this.initScriptFilterSellFormOptions()
    this.initScriptFilterDifficultyOptions()
    this.initScriptEstimatedTimeIdOptions()
    this.initScriptPersonNumOptions()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
    let options = this.customDict.TalkTypeEnum
    options.forEach(e => {
      this.talkTypeOptions.push({ label: e.name, value: e.type })
    })

    options = this.customDict.TFEnum
    options.forEach(e => {
      this.customDictOptions.push({ label: e.name, value: e.type === 'true' ? 1 : 0 })
    })
  },
  methods: {
    bindLogo(logo) {
      this.form.sellerLogo = logo
      this.$forceUpdate()
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.authors = []
      this.producers = []
      this.sellers = []
      this.fileList = []
      this.labels = []
      this.filterThemes = []
      this.form = {
        id: undefined,
        cover: undefined,
        name: undefined,
        englishName: undefined,
        info: undefined,
        label: undefined,
        isDiyAverageHue: false,
        averageHue: '#000000',
        filterBackground: undefined,
        filterTheme: undefined,
        filterDifficulty: undefined,
        estimatedTimeId: undefined,
        filterSellForm: undefined,
        filterType: undefined,
        personNum: undefined,
        humanNum: undefined,
        womanNum: undefined,
        talkType: undefined,
        talkInfo: {
          image: undefined,
          video: undefined,
          text: undefined,
          labels: undefined
        },
        author: undefined,
        seller: undefined,
        sellerLogo: null,
        producer: undefined,
        storyBackground: undefined,
        officialEvaluation: {
          recommendPlayer: null,
          highlight: null,
          story: null,
          experience: null,
          playability: null
        },
        hotValue: undefined,
        hotValueVirtual: undefined,
        likeValue: undefined,
        likeValueVirtual: undefined,
        playedValue: null,
        playedValueVirtual: null,
        salesValue: undefined,
        salesValueVirtual: undefined,
        popularValue: undefined,
        popularValueVirtual: undefined,
        themeValue: undefined,
        themeValueVirtual: undefined,
        likeRankValueVirtual: 0,
        scoreValue: undefined,
        scoreValueVirtual: undefined,
        role: [],
        manualScoreType: null,
        isEnable: undefined,
        isNew: undefined,
        isCloseScore: undefined,
        isPopularTop: undefined,
        releaseTime: undefined,
        isPopinionsTop: undefined,
        isHotTop: undefined,
        isLikeRankTop: undefined,
        isScoreRankTop: undefined,
        createTime: undefined,
        updateTime: undefined,
        creator: undefined,
        modifier: undefined,
        isDeleted: undefined,
        remark: undefined,
        estimatedTime: undefined,
        version: undefined,
        appropriateAgeGroup: null
      }
    },
    initScriptLabelOptions() {
      totalListLabel().then(res => {
        this.scriptLabelOptions = res.data
      })
    },
    initScriptFilterThemeHandleOptions() {
      loadFilterOptions({ type: 2 }).then(res => {
        this.scriptFilterThemeOptions = res.data
      })
    },
    initScriptFilterDifficultyOptions() {
      loadFilterOptions({ type: 3 }).then(res => {
        this.scriptFilterDifficultyOptions = res.data
      })
    },
    initScriptEstimatedTimeIdOptions() {
      loadFilterOptions({ type: 7 }).then(res => {
        this.scriptEstimatedTimeIdOptions = res.data
      })
    },
    initScriptFilterSellFormOptions() {
      loadFilterOptions({ type: 4 }).then(res => {
        this.scriptFilterSellFormOptions = res.data
      })
    },
    initScriptFilterBackgroundOptions() {
      loadFilterOptions({ type: 1 }).then(res => {
        this.scriptFilterBackgroundOptions = res.data
      })
    },
    initScriptFilterTypeOptions() {
      loadFilterOptions({ type: 5 }).then(res => {
        this.scriptFilterTypeOptions = res.data
      })
    },
    initScriptPersonNumOptions() {
      loadFilterOptions({ type: 6 }).then(res => {
        this.scriptPersonNumOptions = res.data
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.isNonAverageHue = true
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      const that = this
      getScript(id).then(response => {
        that.form = response.data
        // that.form.isEnable = that.form.isEnable ? 1 : 0
        console.log('剧本名称：', that.form.name)
        if (typeof that.form.talkInfo === 'string') {
          that.form.talkInfo = JSON.parse(that.form.talkInfo)
        }
        if (typeof that.form.officialEvaluation === 'string') {
          that.form.officialEvaluation = JSON.parse(that.form.officialEvaluation)
        }
        if (typeof that.form.role === 'string') {
          that.form.role = JSON.parse(that.form.role)
        }
        that.open = true
        that.formTitle = '修改'

        // 处理主色调
        if (!that.form.averageHue) {
          that.form.averageHue = '#000000'
          that.isNonAverageHue = true
        } else {
          that.isNonAverageHue = false
        }

        // 处理图片反显
        if (response.data.talkInfo !== undefined && response.data.talkInfo !== null && JSON.stringify(response.data.talkInfo) !== '"{}"') {
          if (that.form.info != null) {
            var infos = that.form.info.split(',')
            for (var j = 0; j < infos.length; j++) {
              that.fileList.push({ status: 'done', url: infos[j], uid: that.getUidRandom(), name: 'image.jpg' })
            }
          }
          // var infos = that.form.info.split(',')
          // for (var j = 0; j < infos.length; j++) {
          //   that.fileList.push({status: 'done', url: infos[j], uid: that.getUidRandom(), name: 'image.jpg'})
          // }
          // 处理标签反显
          if (that.form.label !== '') {
            that.labels = that.form.label.split(',')
          }
          // 处理题材反显
          if (that.form.filterTheme !== '') {
            that.filterThemes = that.form.filterTheme.split(',')
          }
          // 处理作者反显
          /* response.data.authors.forEach(e => {
             that.authors.push({key: e.id, label: e.name})
           }) */
          response.data.sellers.forEach(e => {
            that.sellers.push({ key: e.id, label: e.name })
          })
          /* response.data.producers.forEach(e => {
             that.producers.push({key: e.id, label: e.name})
           }) */
          // 处理角色列表
          if (that.form.role.length === '[]') {
            that.form.role = []
          }
          // that.form.talkInfo = JSON.parse(that.form.talkInfo)
        }
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          that.submitLoading = true
          if (typeof that.form.talkInfo === 'object') {
            that.form.talkInfo = JSON.stringify(that.form.talkInfo)
          }
          if (typeof that.form.officialEvaluation === 'object') {
            that.form.officialEvaluation = JSON.stringify(that.form.officialEvaluation)
          }
          if (typeof that.form.role === 'object') {
            that.form.role = JSON.stringify(that.form.role)
          }
          // 如果不设置自定义主题色 并且之前无主题色 则清空颜色
          if (!that.form.isDiyAverageHue && that.isNonAverageHue) {
            that.form.averageHue = null
          }
          if (that.form.id !== undefined && that.form.id !== null) {
            updateScript(that.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).catch(() => {
              if (typeof that.form.talkInfo === 'string') {
                that.form.talkInfo = JSON.parse(that.form.talkInfo)
              }
              if (typeof that.form.officialEvaluation === 'string') {
                that.form.officialEvaluation = JSON.parse(that.form.officialEvaluation)
              }
              if (typeof that.form.role === 'string') {
                that.form.role = JSON.parse(that.form.role)
              }
            }).finally(() => {
              that.submitLoading = false
            })
          } else {
            addScript(that.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).catch(() => {
              if (typeof that.form.talkInfo === 'string') {
                that.form.talkInfo = JSON.parse(that.form.talkInfo)
              }
              if (typeof that.form.officialEvaluation === 'string') {
                that.form.officialEvaluation = JSON.parse(that.form.officialEvaluation)
              }
              if (typeof that.form.role === 'string') {
                that.form.role = JSON.parse(that.form.role)
              }
            }).finally(() => {
              that.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    coverAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'sript'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'cover', res)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'script'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList.push({ status: 'done', url: res, uid: this.getUidRandom(), name: 'image.jpg' })
          var info = ''
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'info', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

    // 删除图片
    removeFile(file) {
      const _this = this
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      this.fileList = fileList
      var info = ''
      fileList.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'info', info)
      console.log('info=', _this.form.info)
    },
    selectLabelHandleChange(value) {
      this.labels = value
      this.form.label = ''
      this.labels.forEach(e => {
        this.form.label += (e + ',')
      })
      this.form.label = this.form.label.substring(0, this.form.label.length - 1)
    },
    selectFilterBackgroundHandleChange(value) {
      this.form.filterBackground = value
    },
    selectFilterThemeHandleChange(value) {
      if (value.length > 2) {
        this.$message.error('题材最多选择两个')
        var filterThemes = []
        filterThemes.push(value[0])
        filterThemes.push(value[1])
        this.filterThemes = filterThemes
        return
      }
      this.filterThemes = value
      this.form.filterTheme = ''
      this.filterThemes.forEach(e => {
        this.form.filterTheme += (e + ',')
      })
      this.form.filterTheme = this.form.filterTheme.substring(0, this.form.filterTheme.length - 1)
    },
    selectFilterDifficultyHandleChange(value) {
      this.form.filterDifficulty = value
    },
    selectEstimatedTimeIdHandleChange(value) {
      this.form.estimatedTimeId = value
    },
    selectFilterSellFormHandleChange(value) {
      this.form.filterSellForm = value
    },
    selectFilterTypeHandleChange(value) {
      this.form.filterType = value
    },
    selectPersonNumHandleChange(value) {
      this.form.personNum = value
    },
    fetchSellerData(value) {
      loadOptionsByName({ name: value, type: 3 }).then(res => {
        this.sellerData = res.data
      })
    },
    fetchProducerData(value) {
      loadOptionsByName({ name: value, type: 2 }).then(res => {
        this.producerData = res.data
      })
    },
    fetchAuthorChange(value) {
      this.authors = value
      this.form.author = ''
      this.authors.forEach(e => {
        this.form.author += (e.key + ',')
      })
      this.form.author = this.form.author.substring(0, this.form.author.length - 1)
    },
    fetchSellerChange(value) {
      this.sellers = value
      this.form.seller = ''
      this.sellers.forEach(e => {
        this.form.seller += (e.key + ',')
      })
      this.form.seller = this.form.seller.substring(0, this.form.seller.length - 1)
    },

    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.form.releaseTime = dateString
    },
    roleClick(index) {
      this.$refs.multiRoleForm.open(this.form.role)
    },
    updateColor(newColor) {
      this.form.averageHue = newColor.hex
      // 这里可以执行你需要的操作，比如更新数据或者样式
    }
  }

}
</script>
